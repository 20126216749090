$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #ffffff; //
$COLFLAT2: #ffffff; //
$COL3: #a6a7a9; //
$COLFLAT3: #a6a7a9; //
$COL4: #979583; //
$COLFLAT4: #979583; //
$COL5: #262626; //
$COLFLAT5: #262626; //
$COL6: #a6a7a9; //
$COLFLAT6: #a6a7a9; //
$COL7: #979583; //
$COLFLAT7: #979583; //
$COL8: #ffffff; //
$COLFLAT8: #ffffff; //
$COL9: #979583; //
$COLFLAT9: #979583; //
$COL10: #979583; //
$COLFLAT10: #979583; //
$COL11: #979583; //
$COLFLAT11: #979583; //
$COL12: #979583; //
$COLFLAT12: #979583; //
$COL13: #616052; //
$COLFLAT13: #616052; //
$COL14: #979583; //
$COLFLAT14: #979583; //
$COL15: #ffffff; //
$COLFLAT15: #ffffff; //
$COL16: #262626; //
$COLFLAT16: #262626; //
$COL17: #e8e8e8; //Light Grey
$COLFLAT17: #e8e8e8; //Light Grey
$COL18: #262626; //Dark
$COLFLAT18: #262626; //Dark
$COL19: #262626; //
$COLFLAT19: #262626; //
$COL20: #262626; //
$COLFLAT20: #262626; //
$COL21: rgba(38,38,38,0.769); //Dark transparent hover
$COLFLAT21: #3b3b3b; //Dark transparent hover
$COL22: rgba(151,149,131,0.302); //Olive transparent hover
$COLFLAT22: #878681; //Olive transparent hover
$COL23: rgba(255,255,255,0.102); //White 10%
$COLFLAT23: #8d8d8d; //White 10%
$COL24: rgba(255,255,255,0.871); //White transparent
$COLFLAT24: #efefef; //White transparent
$COL25: #616052; //
$COLFLAT25: #616052; //
$COL26: rgba(255,255,255,0.722); //White transparent 50%
$COLFLAT26: #dcdcdc; //White transparent 50%
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
