body { background-color: $COL2; }
body { color: $COL5; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL9;
font-family: 'Catamaran';
font-weight: 700;
font-style: normal;
line-height: 1;
letter-spacing: 0;
font-size: 27px;

@media #{$medium-up} {
font-size: 50px;

}
@media #{$large-up} {
font-size: 60px;

}
}
h2 {
color: $COL10;
font-family: 'Catamaran';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h3 {
color: $COL11;
font-family: 'Catamaran';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL12;
font-family: 'Catamaran';
font-weight: 100;
font-style: normal;
line-height: 1;
letter-spacing: -.01em;
font-size: 18px;

@media #{$medium-up} {
font-size: 32px;

}
}
h5 {
color: $COL13;
font-family: 'Catamaran';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

}
h6 {
color: $COL14;
font-family: 'Catamaran';
font-weight: 300;
font-style: normal;
line-height: 1.2;
font-size: 24px;

@media #{$medium-up} {
font-size: 28px;

}
@media #{$large-up} {
font-size: 30px;

}
}
.button {
font-family: 'Catamaran';
font-weight: 700;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
.me-Quote .quote-body {
font-family: 'Noto Serif';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
.me-Quote .quote-author {
font-family: 'Catamaran';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
ol,ul {
color: $COL16;
font-family: 'Catamaran';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: $COL15;
font-family: 'Catamaran';
font-weight: 100;
font-style: normal;
line-height: 1.1;
text-decoration: underline;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
summary {
font-family: 'Catamaran';
font-weight: 100;
font-style: normal;
line-height: 1;
letter-spacing: -.01em;
font-size: 18px;

@media #{$medium-up} {
font-size: 32px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Catamaran';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'Catamaran';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
nav.me-RespMenu.responsive-menu a {
font-family: 'Catamaran';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
body {
font-family: 'Catamaran';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
p { padding-bottom: 1em; }
a {color: $COL4}
a:hover {color: $COL6}
/* Secondary:2 */
.MES2 {
background-color: $COL3;
color: $COL19;
 }
/* Secondary:3 */
.MES3 {
background-color: $COL3;
color: $COL19;
 }
.MES3 {
background-color: $COL3;
color: $COL19;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL19;
 }
 }
cite.MEC3{
color: $COL19;
}
/* Primary:4 */
.MES4 {
background-color: $COL7;
color: $COL8;
 }
/* Primary:5 */
.MES5 {
background-color: $COL7;
color: $COL8;
 }
.MES5 {
background-color: $COL7;
color: $COL8;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL8;
 }
 }
cite.MEC5{
color: $COL8;
}
/* footer line:6 */
.MES6 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: $COL17 transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Main Menu:7 */
nav.me-Menu.MES7 {
& .menu-item.MEC7, & .menu-item.MEC7 > div.MEC7{ & > a.MEC7{color: $COL18;
text-transform: uppercase;
}
 &:hover > a.MEC7{color: $COL25;
}
 }
&.horizontal > .menu-item.MEC7 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC7 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC7 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC7 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px;}

@media #{$medium-up} {
& > .menu-item > a{padding: 30px 7px;}

}
& .sub-menu .menu-item a{padding: 5px 10px;}


&.horizontal .menu-item.MEC7:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL24;}}
&.vertical .menu-item.MEC7:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL24;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC7{background-color: $COL24;
 &:hover {background-color: $COL26}
}
& .menu-item.MEC7, & .menu-item.MEC7 > div.MEC7{ & > a.MEC7{color: $COL20;
}
 &:hover > a.MEC7{color: $COL20;
}
 }

}
}
 }
/* Footer Panel:8 */
.MES8 {
background-color: $COL7;
color: $COL2;
font-size: 14.4px;
 }
.MES8 {
background-color: $COL7;
color: $COL2;
font-size: 14.4px;
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: $COL2;
 }
 }
cite.MEC8{
color: $COL2;
font-size: 14.4px;
}
/* Home Image Block Panel:9 */
.MES9 {
background-color: $COL18;
color: $COL2;
border-width: 2px 2px 0 2px;
@media #{$medium-up} {
border-width: 2px 0 0 2px;
};
border-style: solid;
border-color: $COL2;
 }
.MES9 {
background-color: $COL18;
color: $COL2;
border-width: 2px 2px 0 2px;
@media #{$medium-up} {
border-width: 2px 0 0 2px;
};
border-style: solid;
border-color: $COL2;
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: $COL2;
 }
 }
cite.MEC9{
color: $COL2;
}
/* Slider Border:10 */
.MES10 {
 }
.MES10 {
 }
/* Home Slider:11 */
.MES11 {
& .slider-arrow {color: $COL17;
font-size: 40px;
@media #{$medium-up} {
font-size: 60px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: $COL2;
} }
/* Footer Menu:12 */
nav.me-Menu.MES12 {
& .menu-item.MEC12, & .menu-item.MEC12 > div.MEC12{ & > a.MEC12{color: $COL2;
text-transform: uppercase;
}
 &:hover > a.MEC12{color: $COL2;
}
 }
&.horizontal > .menu-item.MEC12 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC12 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC12 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC12 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}

& > .menu-item.MEC12.active { & > a{ color: $COL2;}
 }

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* :18 */
nav.responsive-menu {
.menu-item.MEC18{background-color: $COL7;
}
& .menu-item.MEC18, & .menu-item.MEC18 > div.menu-item-wrap{ & > a.MEC18, & > i{color: $COL2;
text-transform: uppercase;
text-align: left;
}
  }
& .menu-item.MEC18 { border:0;
border-color: $COL17;
border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 15px 40px;}

& .sub-menu{ .sub-menu {}}

 }
/* Hollow:13 */
.MES13 {
background-color: $COL1;
&:hover { background-color: $COL22;}
color: $COL2;
&:hover { color: $COL2;}
font-size: 18px;
@media #{$medium-up} {
font-size: 23.4px;
};
@media #{$large-up} {
font-size: 25.2px;
};
padding: 7px 40px;

border-width: 3px;
@media #{$medium-up} { 
border-width: 4px;
};
border-style: solid;
border-color: $COL2;
&:hover { border-color: $COL2; }
 }
/* Alternate:14 */
.MES14 {
background-color: $COL20;
 }
/* Alternate:15 */
.MES15 {
background-color: $COL20;
 }
.MES15 {
background-color: $COL20;
 }
/* Image Overlay Panel:16 */
.MES16 {
&:hover, &.hover { background-color: $COL22;}
color: $COL2;
 }
.MES16 {
&:hover, &.hover { background-color: $COL22;}
color: $COL2;
h1.MEC16, h2.MEC16, h3.MEC16, h4.MEC16, h5.MEC16, h6.MEC16 { color: $COL2;
 }
 }
cite.MEC16{
color: $COL2;
}
/* Image Overlay Panel For Radio Mask:19 */
.MES19 {
color: $COL2;
 }
.MES19 {
color: $COL2;
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: $COL2;
 }
 }
cite.MEC19{
color: $COL2;
}
/* Form background:20 */
.MES20 {
background-color: $COL7;
color: $COL8;
padding: 15px;

@media #{$medium-up} {
padding: 20px 40px;

}
 }
.MES20 {
background-color: $COL7;
color: $COL8;
padding: 15px;

@media #{$medium-up} {
padding: 20px 40px;

}
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: $COL8;
 }
 }
cite.MEC20{
color: $COL8;
}
/* Scroll to Explore:21 */
.MES21 {
background-color: $COL21;
color: $COL2;
border-radius: 20px 20px 0 0;
padding: 10px 20px;

@media #{$medium-up} {
padding: 15px 40px;

}
 }
/* Header panel:22 */
.MES22 {
background-color: $COL24;
 }
.MES22 {
background-color: $COL24;
 }
/* Dark:23 */
.MES23 {
background-color: $COL25;
 }
/* Dark:24 */
.MES24 {
background-color: $COL25;
 }
.MES24 {
background-color: $COL25;
 }
/* Transparent panel:25 */
.MES25 {
background-color: $COL26;
 }
.MES25 {
background-color: $COL26;
 }
/* Slider Text Overlay:26 */
.MES26 {
 }
.MES26 {
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: $COL2;
 }
h1.MEC26 { font-size: 54px; }
h1.MEC26 { @media #{$medium-up} { font-size: 100.5px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 150.6px; }; }
h2.MEC26 { font-size: 44px; }
h2.MEC26 { @media #{$medium-up} { font-size: 70.35px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 100.4px; }; }
h3.MEC26 { font-size: 40px; }
h3.MEC26 { @media #{$medium-up} { font-size: 60.3px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 75.3px; }; }
h4.MEC26 { font-size: 36px; }
h4.MEC26 { @media #{$medium-up} { font-size: 64.32px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 80.32px; }; }
h5.MEC26 { font-size: 40px; }
h5.MEC26 { @media #{$medium-up} { font-size: 40.2px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 50.2px; }; }
h6.MEC26 { font-size: 48px; }
h6.MEC26 { @media #{$medium-up} { font-size: 56.28px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 75.3px; }; }
 }
/* Page Slider:27 */
.MES27 {
& .slider-arrow {color: $COL17;
font-size: 40px;
@media #{$medium-up} {
font-size: 60px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: $COL2;
} }
